import React, { useState,useEffect } from 'react';
import { graphql } from "gatsby"
import _ from 'lodash'
import tw from 'twin.macro'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { A, H1 } from '../components/styles'
import Layout from "../components/layout"
import Group from "../components/group"

export const pageQuery = graphql`
 query {
  allStrapiWork(sort: { fields: [created_at], order: DESC}){
    edges {
     node {
        created_at
        work_categories {
         title
         link {
            url
         }
       }
      }
    }
  }
}`

export default function IndexPage({ pageContext: { node,list },location,data }) {
    const workNode = data.allStrapiWork.edges[0].node
    const [navVisible,setNavVisible] = useState(false)
    const [navIndex,setNavIndex] = useState(0)
    const styles = {
        Li:[
            tw`relative pl-5 text-xl font-bold`,
            tw`after:content-none after:w-2 after:h-2 after:border after:border-white after:rounded-full after:absolute after:left-0 after:top-2.5`,
            tw`hover:after:bg-white`
        ]
    }
    let listArr = []
    let listObj = _.groupBy(list, 'type')
    for(let i in listObj){
        listArr.push(listObj[i])
    }
    const setNavFun = ()=>{
        switch(location.pathname.split("/")[2]){
            case "studios":
                setNavIndex(0);
                break;
            case "capabilities":
                setNavIndex(1);
                break;
            case "industries":
                setNavIndex(2);
                break;
                default:
                setNavIndex(0);
        }
    }
    useEffect(()=>{
        setNavFun()
        document.addEventListener("click",()=>{
            setNavVisible(false)
            setNavFun()
        })
    },[])
    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <div className="pb-[200px] lg:pb-[120px]">
                <h1 className="text-[200px] leading-none pb-[95px] lg:pb-[50px] sm:pb-10 pt-[260px] lg:pt-[165px] xl:text-[150px] lg:text-[calc(50px+10vw)]">{node.title}</h1>
                <div>
                    <ul className="text-[28px] sm:text-[22px] pb-6 font-bold text-[#bbb] flex items-center flex-wrap md:flex-col md:items-start">
                        <li><a href="/work" className="hover:text-black">All</a></li>
                        {
                            workNode.work_categories.map((n,i)=>{
                                return <li key={i} onClick={(e)=>{setNavVisible(true);e.nativeEvent.stopImmediatePropagation();setNavIndex(i)}} className={`hover:text-black hover:cursor-pointer ml-8 md:ml-0 ${navIndex==i?'text-black':''}`}>{n.title}</li>
                            })
                        }
                    </ul>
                    <div onClick={(e)=>{e.nativeEvent.stopImmediatePropagation();}} className={`bg-black w-full h-screen text-white absolute left-0 z-[2] ${navVisible?'':'hidden'}`}>
                        <div className="container mx-auto w-full relative">
                            <span className="absolute right-8 -top-[58px] hover:cursor-pointer" onClick={()=>{setNavVisible(false);setNavFun()}}><svg width="24" height="24" fillRule="evenodd"><path d="m4.6 3 17 17-2.2 2-17-17z"/><path d="m2.5 20 17-17 2 2-17 17z"/></svg></span>
                            {
                                listArr.map((n,i)=>{
                                    return (
                                        <ul key={i} className={`grid grid-cols-4 lg:grid-cols-2 xs:grid-cols-1 gap-4 pt-10 pb-20 ${navIndex==i?'':'hidden'}`}>
                                            {
                                                n.map((e,k)=>{
                                                    var f = true
                                                    workNode.work_categories.forEach(p => {
                                                        if(p.title==e.title){
                                                            f = false
                                                        }
                                                    });
                                                    if(f){
                                                        return <li css={styles.Li} className={e.url == location.pathname?'after:bg-white':''}><a href={e.url}>{e.title}</a></li>
                                                    }
                                                })
                                            }
                                        </ul>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="works-block">
                    {
                        node.work_details.map((n,i)=>{
                            if(n.recommend){
                                return (
                                    <Group key={i} node={n}/>
                                )
                            }
                        })
                    }
                    </div>
                    <A href="/work" variant="more">Show more work</A>
                </div>
            </div>
        </Layout>
    )
}

